<template>
  <div class="container">
    <div class="condition">
      <div class="logo">
        <img :src="info.logo || '//img0.qll-times.com/cdn/zgimg/zg-logo.jpg'">
        <span @click="updateLogo">修改</span>
        <el-tooltip effect="dark" placement="bottom"
          content="该Logo将显示在企管后台，以及作为企业动态中公司的头像">
          <i class="zgcms">&#xe661;</i>
        </el-tooltip>
      </div>
      <div class="item">
        <label>企业简称：</label>
        <span class="title">{{info.shortName}}</span>
        <span style="float: right; cursor: pointer;">
          <span @click="updateName(info.shortName)" class="button">修改</span>
          <el-tooltip effect="dark" placement="bottom"
            content="企业简称将显示在企业动态、后台名称、AI名片姓名下">
            <i class="zgcms">&#xe661;</i>
          </el-tooltip>
        </span>
      </div>
      <div class="item">
        <label>企业名称：</label>
        <span class="title">{{info.name}}</span>
      </div>
      <div class="item">
        <label>成员数量：</label>
        <span class="title">{{info.memberNumber}} / {{info.memberLimit}}</span>
        <span style="float: right; cursor: pointer;">
          <el-tooltip effect="dark" placement="bottom"
            content="数值为“现有成员数/最大成员数”，若需要增加最大成员数，请联系你的代理商">
            <i class="zgcms">&#xe661;</i>
          </el-tooltip>
        </span>
      </div>
      <div class="item">
        <label>到期时间：</label>
        <span class="title">{{info.expireAt | dateFormat}}</span>
      </div>
      <div class="item">
        <label>企业地址：</label>
        <span class="title">{{info.address}}</span>
        <span style="float: right; cursor: pointer;">
          <span @click="updateAddress(info.address)" class="button">修改</span>
          <i class="zgcms" style="opacity:0">&#xe661;</i>
        </span>
      </div>
      <div class="item">
        <label>企业简介：</label>
        <span class="title desc">
          <el-input type="textarea" rows="5" :disabled="descIsDisabled"
            @change="updateMyCompany({ description: info.description }); descIsDisabled = true"
            placeholder="请输入企业简介" v-model="info.description" maxlength="300" show-word-limit>
          </el-input>
        </span>
        <span style="float: right; cursor: pointer;" v-if="descIsDisabled">
          <span @click="descIsDisabled = false" class="button">修改</span>
          <i class="zgcms" style="opacity:0">&#xe661;</i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import crooperImage from '@/websites/cms/js/dialog'
export default {
  data(){
    return {
      info: {
        balance: 0.00,
        name: "",
        logo: "",
        description: ""
      },
      descIsDisabled: true
    }
  },
  created() {
    this.getCompanyInfo(); 
  },
  methods: {
    // 获取企业信息
    async getCompanyInfo(){
      let res = await this.$ZGManager.getMyCompany();
      if(res.status == 200 && res.data){
        Object.assign(this.info, res.data);
        this.info.balance =  Number(this.info.balance).toFixed(2);
      }
    },

    // 修改公司logo
    updateLogo(){
      crooperImage.show({
        type: 'crooperImage',
        cropWidth: 100,
        cropHeight: 100,
        fixedArray: ['1', '1'],
        selected: (item) => {
          this.info.logo = item.url;
          this.updateMyCompany({logo: this.info.logo});
        }
      })
    },

    // 修改公司简称
    async updateName(name){
      let dialog = await this.$prompt('', '请输入企业简称', {
        inputValue: name,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.{0,6}?$/,
        inputErrorMessage: '企业简称不能超过6个文字'
      });
      if(dialog.action != 'confirm') return;
      this.updateMyCompany({shortName: dialog.value});
    },

    // 修改公司地址
    async updateAddress(address){
      let dialog = await this.$prompt('', '请输入企业地址', {
        inputValue: address,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      });
      if(dialog.action != 'confirm') return;
      this.updateMyCompany({address: dialog.value});
    },

    // 更新公司信息
    async updateMyCompany(data){
      let res = await this.$ZGManager.updateMyCompany(this.info.id, data);
      if(res.status == 200){
        this.$message.success('更新成功');
        this.getCompanyInfo();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.condition{
  padding: 30px 60px;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  .logo{
    width: 80px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;
    img{
      width: 100%;
      margin-bottom: 5px;
    }
    span{
      color: #6e87bd;
      cursor: pointer;
      padding-left: 10px;
    }
    i{
      color: #bbbbbb;
      float: right;
      cursor: pointer;
      padding-right: 8px;
    }
  }
  .item{
    font-size: 14px;
    line-height: 60px;
    width: 100%;
    display: inline-block;
    label{
      color: #666;
    }
    .title{
      color: #454545;
    }
    .desc{
      width: calc(100% - 400px);
      display: inherit;
      vertical-align: text-top;
    }
    .button{
      color: #6e87bd;
    }
    i{
      color: #bbbbbb;
    }
  }
}
</style>